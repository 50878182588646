import { get, post } from 'utils/apiWrapper';
import { prepareHeadersFromContext } from 'utils/helpers';
import { CONTACT_INFO } from 'utils/routes';

export const createContact = ({ lang, token, values }) => new Promise((resolve, reject) => {
  const path = CONTACT_INFO;

  const formdata = new FormData();
  formdata.append('profile', lang);
  formdata.append('token', token);
  const filesNodes = [ 'files', 'fichero' ];
  Object.entries(values).forEach((value) => {
    if (value[0] !== 'agreement') {
      if (filesNodes.includes(value[0])) {
        value[1].forEach((file) => formdata.append(value[0], file));
      } else {
        formdata.append(value[0], value[1]);
      }
    }
  });

  post({
    path,
    data: formdata,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(({ data }) => {
      const {
        status,
      } = data;
      if (status !== 'OK') {
        reject(status);
      } else {
        resolve({ status });
      }
    }).catch(reject);
});

export async function getContactInfo({
  context, ...rest
}) {
  const searchParams = new URLSearchParams();
  Object.entries(rest).forEach(([ key, value ]) => {
    if (value !== undefined && value !== null) {
      searchParams.append(key, value);
    }
  });
  const path = `${CONTACT_INFO}?${searchParams.toString()}`;
  const headers = prepareHeadersFromContext(context);
  console.log(process.env.API_GATEWAY + path);
  const { data } = await get({ path, headers });
  if (data.status === 'KO') {
    throw data.errors;
  }
  return data;
}

export const getContactFormInfo = ({
  context, lang,
}) => new Promise((resolve, reject) => {
  const path = `${CONTACT_INFO}/form?profile=${lang}`;
  const headers = prepareHeadersFromContext(context);

  get({ path, headers }).then(({ data }) => {
    if (data.status === 'OK') {
      delete data.audit;
      delete data.status;
      resolve(data);
    } else {
      reject(data.errors);
    }
  });
});
